import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Svg from 'react-inlinesvg'
import MainMenu from "./MainMenu"

const Header = () => {
  const data = useStaticQuery(query)

  return (
    <header className="site-header">
      <div className={`wrapper flex`}>
        <div className="branding">
          <Link to={`/`}>
            <Svg src={data.wp.globalSettings.globalSettings.logo.localFile.publicURL} title="LeJeune Steel logo" />
          </Link>
        </div>
        <MainMenu />
      </div>
    </header>
  )
}

export default Header

const query = graphql`
  query Logo {
    wp {
      globalSettings {
        globalSettings {
          logo {
            sourceUrl
            id
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
