import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, seoImage, title, seoTitle }) => {
  
  const data = useStaticQuery(query)
  const fbImg = data.wp.globalSettings.globalSettings.fallbackMetaImage.localFile.url

  const seo = {
    image: seoImage || fbImg,
  }

  const {href} = useLocation()

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={seoTitle}
      link={[
        {
          rel: `canonical`,
          href: href,
        }
      ]}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `image`,
          content: seo.image,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: fbImg,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata?.author || ``,
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  author: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO

const query = graphql`query Site {
  wp {
    seo {
      schema {
        companyName
        companyLogo {
          sourceUrl
        }
      }
      contentTypes {
        page {
          metaDesc
        }
      }
    }
    globalSettings {
      globalSettings {
        fallbackMetaImage {
          localFile {
            url
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`