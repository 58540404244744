import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { flatListToHierarchical } from "./Helpers"
import Nav from "./Navigation"

const FooterMenu = () => {
  const data = useStaticQuery(query)

  const footerPrimaryMenu = flatListToHierarchical(data.footer.nodes)
  const footerSecondaryMenu = flatListToHierarchical(data.secondary.nodes)
  const footerIndustryMenu = flatListToHierarchical(data.industry.nodes, {
    idKey: "id",
    childrenKey: "routes",
    parentKey: "parent",
  })

  return (
    <div className={`menu-wrapper flex`}>
      <Nav items={footerPrimaryMenu} navClass="menu footer-menu flex flex-column" />
      <Nav items={footerIndustryMenu} navClass="menu industry-menu flex flex-column" />
      <Nav items={footerSecondaryMenu} navClass="menu industry-menu flex flex-column" />
    </div>
  )
}

export default FooterMenu

const query = graphql`
  query Menus {
    footer: allWpMenuItem(filter: {menu: {node: {name: {eq: "footer-menu"}}}}) {
      nodes {
        key: id
        title: label
        url
        parentId
        cssClasses
        target
      }
    }
    secondary: allWpMenuItem(filter: {menu: {node: {name: {eq: "secondary-footer-menu"}}}}) {
      nodes {
        key: id
        title: label
        url
        parentId
        cssClasses
        target
      }
    }
    industry: allWpMenuItem(filter: {menu: {node: {name: {eq: "industry-menu"}}}}) {
      nodes {
        key: id
        title: label
        url
        parentId
        cssClasses
        target
      }
    }
  }
`
