import React from 'react'
import {Link} from 'gatsby'

const Button = ({link, section, space}) => {

  const sClass = section ? 'on-bkg' : ''
  const url = link.url || link
  const internal = /^\/(?!\/)/.test(url)
  const title = link.title || 'Read More'
  const spacing = space ? ` ${space}` : ''
  
  if (internal) {
    return (
      <>
        <Link className={`button ${sClass}${spacing}`} to={url}>{title}</Link>
      </>
    )
  }

  return (
    <>
      <a className={`button ${sClass}`} href={url} target={link.target ? link.target : null} rel={link.target ? 'noopener noreferrer' : null}>{title}</a>
    </>
  )
}

export default Button