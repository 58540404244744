import {useCallback} from 'react'
import classNames from 'classnames'

export const flatListToHierarchical = (
  data = [],
  {idKey='key',parentKey='parentId',childrenKey='children'} = {}
) => {
  const tree = [];
  const childrenOf = {};
  data.forEach((item) => {
      const newItem = {...item};
      const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
      childrenOf[id] = childrenOf[id] || [];
      newItem[childrenKey] = childrenOf[id];
      parentId
          ? (
              childrenOf[parentId] = childrenOf[parentId] || []
          ).push(newItem)
          : tree.push(newItem);
  });
  return tree;
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function shuffle(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export const useStandardStyles = (props) => {
  return useCallback(data => {
    const comp = props
    const bkg = data.shade ? 'shade' : null
    const mt = data.margins.marginTop !== 'default' ? data.margins.marginTop : null
    const mb = data.margins.marginBottom !== 'default' ? data.margins.marginBottom : null
    const pt = data.padding.paddingTop !== 'default' ? data.padding.paddingTop : null
    const pb = data.padding.paddingBottom !== 'default' ? data.padding.paddingBottom : null
    const side = data.imageSide ? `img-${data.imageSide}` : null
    
    return classNames('section', comp, mt, mb, bkg, pt, pb, side)
  }, [props]);
}