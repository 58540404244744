import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import FooterMenu from './FooterMenu'
import Button from './Button'
import Svg from 'react-inlinesvg'

const Footer = () => {

  const data = useStaticQuery(query)
  const globals = data.wp.globalSettings.globalSettings
  const socials = globals.socialLinks
  const today = new Date()
  let year = today.getFullYear()
  const address = data.wp.globalSettings.globalSettings.address

  return (
    <footer className={`site-footer wrapper flex`}>
      <FooterMenu />
      <div className={`address-block`}>
        {parse(address)}
        <div>
          <Button link={data.wp.globalSettings.globalSettings.loginLink} />
        </div>
        <div className={`social flex`}>
          {socials && socials.map((social, i) => (
            <div className={`svg-wrapper`} key={i}><a href={social.link} target="_blank" rel="noreferrer noopener" aria-label="Social Icon"><Svg className={`svg`} src={social.icon.localFile.publicURL} /></a></div>
          ))}
        </div>
        <p className="text-sm">&copy; {year} LeJeune Steel. All Rights Reserved.<br/>LeJeune Steel is a subsidiary of <a href="https://apigroupinc.com/">APi Group, Inc.</a></p>
      </div>
    </footer>
  )
}

export default Footer

const query = graphql`
  query Footer {
    wp {
      globalSettings {
        globalSettings {
          address
          loginLink {
            url
            title
            target
          }
          socialLinks {
            link
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`