import React from "react"
import { Link } from "gatsby"

const MenuItem = props => {
  
  const { title, url, target } = props
  const classes = props.cssClasses
  const internal = /^\/(?!\/)/.test(url)
  const parent = props.parentId === null ? 'parent' : undefined

  if (internal) {
    return <Link className={`${parent} ${classes}`} activeClassName="current" to={url}>{title}</Link>
  }

  return (
    <a className={`${parent} ${classes}`} href={url} target={target ? target : null} rel={target ? 'noopener noreferrer' : null}>{title}</a>
  )
}

export default MenuItem
