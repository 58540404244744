import React from "react"
import MenuItem from "./MenuItem"

const Nav = ({ items, navClass }) => {

  return (
    <nav className={`${navClass}`}>
      {items &&
        items.map((item, i) => (
          <MenuItem key={i} {...item} />
        ))}
    </nav>
  )
}

export default Nav
